import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    const cur_time = new Date().toISOString();
    checkFirstVisitCookie()
    console.log("Connected to the Notification channel!")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Disconnected from the Notification channel!")
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data["redirection_path"] != "" && data["redirection_path"] == window.location.pathname) {
    	data["redirection_path"] = "";
    }
	if (window.location.pathname == "/home" && data["redirection_path"] == "/streaming") {
		$("#loungeHotspot").fadeOut();
	}
    notify_user(data);
    var today = new Date();
	var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    console.log("At " + time + ": received some data");
    console.log(data);
  }
});
