// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

import jquery from 'jquery'
import 'bootstrap'
import 'data-confirm-modal'

/*
$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})
*/
$(window).on("load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

// import the application.scss we created for the CSS
import "../css/application.scss";


// copy all static images under ../img and ../svg to the output folder,
// and you can reference them with <%= image_pack_tag 'media/img/abc.png' %> or <%= image_pack_tag 'media/svg/def.svg' %>
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)

const svgs = require.context('../svg', true)
const svgPath = (name) => svgs(name, true)

import ahoy from "ahoy.js"
import "timeago";

// ***** All elements used in theme
// import or require all necessary files from template that have their own npm package
import "@yaireo/tagify";
import "@fancyapps/fancybox/dist/jquery.fancybox.min";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
require("appear/dist/appear");
import "bootstrap";
import "chart.js";
import "chartjs-chart-matrix";
import "chartjs-plugin-datalabels";
import Circles from "circles.js";
import "clipboard";
import "datatables";
import "datatables.net-buttons";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "dropzone";
import "flag-icon-css/css/flag-icon.min.css"
import "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "ion-rangeslider";
import "ion-rangeslider/css/ion.rangeSlider.css";
import "jquery-mask-plugin";
import "jquery-migrate";
import "jquery-validation";
import "jszip";
import "leaflet";
import "list.js";
import "pdfmake";
import "pwstrength-bootstrap/dist/pwstrength-bootstrap.min";
import "quill";
import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import "sortablejs";
// there's no "main" key specified in package.json, and the javascript file doesn't export as module, we have to use require
require("table-edits/build/table-edits.min");


// custom libraries that don't have a npm package
import "../vendor/codyhouse-calendar/util"
import "../vendor/babel-polyfill/polyfill.min"
import "../vendor/chart.js.extensions/chartjs-extensions"

import "../vendor/hs-add-field/hs-add-field.min"
import "../vendor/hs-count-characters/hs-count-characters"
import hscounter from "../vendor/hs-counter/hs-counter.min"
import "../vendor/hs-file-attach/hs-file-attach.min"
import "../vendor/hs-header-fullscreen/hs-header-fullscreen.min"
import hsmegamenu from "../vendor/hs-mega-menu/hs-mega-menu.min"
import "../vendor/hs-mega-menu/hs-mega-menu.min.css"
import hsgoto from "../vendor/hs-go-to/hs-go-to.min"
import typed from "../vendor/typed.js/typed.js"

import hsheader from "../vendor/hs-header/hs-header.min"
import hsunfold from "../vendor/hs-unfold/hs-unfold.min"
import "../vendor/hs-unfold/hs-unfold.min.css"
import hsshowanimation from "../vendor/hs-show-animation/hs-show-animation.min"
import hsstickyblock from "../vendor/hs-sticky-block/hs-sticky-block.min"
import "../vendor/cubeportfolio/js/jquery.cubeportfolio.min"
import "../vendor/cubeportfolio/css/cubeportfolio.min.css"
import "../vendor/jquery-countdown/jquery.countdown.min"

import "../vendor/dzsparallaxer/dzsparallaxer"
import "../vendor/dzsparallaxer/dzsparallaxer.css"

import aos from "../vendor/aos/aos"
import "../vendor/aos/aos.css"
import hsscrollnav from "../vendor/hs-scroll-nav/hs-scroll-nav.min"
import "../vendor/slick-carousel/slick/slick"
import "../vendor/slick-carousel/slick/slick.css"
import hstogglestate from "../vendor/hs-toggle-state/hs-toggle-state.min"
import hsvideoplayer from "../vendor/hs-video-player/hs-video-player.min"
import hsvideobg from "../vendor/hs-video-bg/dist/hs-video-bg.min"
import "../vendor/hs-video-bg/dist/hs-video-bg.min.css"
import hsswitchtext from "../vendor/hs-switch/hs-switch-text.min"

// other js libraries installed manually (not from theme)
import "../vendor/jquery-ui/jquery-ui.min"
import "../vendor/jquery-ui/jquery-ui.min.css"
import confettigenerator from "../vendor/confetti-js/confetti"

// main theme files
import "../src/theme-custom";
import "../src/theme.min";

// all hs files hosted within ../src (if called earlier it generates errors)
import hsslickcarousel from "../src/hs.slick-carousel"
import "../src/hs.circles"
import hsquill from "../src/hs.quill"
import hsionrangeslider from "../src/hs.ion-range-slider"
// temporarily commented as it generates errors
// import "../src/hs.autocomplete-local-search"

// export jquery to global
/*var jQuery = require("jquery/dist/jquery")(window);

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;*/


window.jQuery = jquery
window.jquery = jquery
window.$ = jquery
global.$ = global.jQuery = global.jquery = jquery



// make all special objects ready to use in all pages
global.ahoy = ahoy
window.ahoy = ahoy

global.HSHeader = hsheader
window.HSHeader = hsheader

global.HSMegaMenu = hsmegamenu
window.HSMegaMenu = hsmegamenu

global.Typed = typed
window.Typed = typed

global.AOS = aos
window.AOS = aos

global.HSShowAnimation = hsshowanimation
window.HSShowAnimation = hsshowanimation

global.HSCounter = hscounter
window.HSCounter = hscounter

global.HSStickyBlock = hsstickyblock
window.HSStickyBlock = hsstickyblock

global.HSSlickCarousel = hsslickcarousel
window.HSSlickCarousel = hsslickcarousel

global.HSGoTo = hsgoto
window.HSGoTo = hsgoto

global.HSUnfold = hsunfold;
window.HSUnfold = hsunfold;

global.HSScrollNav = hsscrollnav;
window.HSScrollNav = hsscrollnav;

global.HSToggleState = hstogglestate;
window.HSToggleState = hstogglestate;

global.HSVideoPlayer = hsvideoplayer;
window.HSVideoPlayer = hsvideoplayer;

global.HSVideoBg = hsvideobg;
window.HSVideoBg = hsvideobg;

global.HSSwitchText = hsswitchtext;
window.HSSwitchText = hsswitchtext;

global.HSIonRangeSlider = hsionrangeslider;
window.HSIonRangeSlider = hsionrangeslider;

global.HSQuill = hsquill;
window.HSQuill = hsquill;

global.Circles = Circles;
window.Circles = Circles;

// End of All elements used in theme

global.ConfettiGenerator = confettigenerator;
window.ConfettiGenerator = confettigenerator;